import React from "react";
import { useAuth } from "oidc-react";
import { Link } from "react-router-dom";
import {Button, Flex} from '@fluentui/react-northstar';
import { SkypeLogoIcon } from '@fluentui/react-icons-northstar'

export default function Layout(props){
    const auth = useAuth();
    if (auth && auth.userData) {
    return(
        <Flex column gap="gap.small">
            <Flex space="between" wrap>
                <Button onClick={()=>console.log(auth.userData.access_token)}><SkypeLogoIcon />你好! {auth.userData.profile.name}🎉</Button>
                <Button onClick={() => auth.signOut()}>登出</Button>
            </Flex>
            <Flex gap="gap.small" wrap>
            <Button as={Link} to='/'>主页</Button>
            <Button as={Link} to='/use'>使用方法</Button>
            <Button><a href='https://pass.rainballs.com'>管理Pass账户</a></Button>
            {auth.userData.profile.name==="plxdhong@rainballs.com"?<Button><Link to='/user'>User</Link></Button>:null}
            </Flex>
            {props.children}
        </Flex>
    )}else{
        return <Flex column hAlign="center" vAlign="center"><Button onClick={()=>auth.signIn()}>请点击登录</Button></Flex>
    }
}