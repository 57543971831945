import './App.css';
import { AuthProvider } from 'oidc-react';
import Layout from "./pages/Layout";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import Use from "./pages/Use";
import Home from  "./pages/Home";
import User from './pages/User';
import { Provider, teamsTheme } from '@fluentui/react-northstar'

const oidcConfig = {
  onSignIn: async (user) => {
    alert('登陆成功');
    console.log(user);
    window.location.hash = '';
  },
  authority:  process.env.NODE_ENV === 'development'?'https://localhost:3000/':'https://pass.rainballs.com/',
  clientId:
    'cfewClient',
  responseType: 'code',
  scope: 'openid profile offline_access api_cfew role',
  redirectUri:
    process.env.NODE_ENV === 'development'
      ? 'https://localhost:3000/'
      : 'https://cfew.rainballs.com/',
};

export default function App() {
  return(
  <Provider theme={teamsTheme}>
    <AuthProvider
      {...oidcConfig}
    ><Router>
      <Layout>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/use" component={Use} />
            <Route exact path="/user" component={User}/>
        </Switch>
      </Layout></Router>
    </AuthProvider>
  </Provider>
)};
