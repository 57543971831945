import React from "react";
import { Button, Card, Flex, Header, Text } from '@fluentui/react-northstar'
import A1 from '../img/A1.png';
import A2 from '../img/A2.png';
import A3 from '../img/A3.png';
import I1 from '../img/I1.jpg';
import I2 from '../img/I2.jpg';
import P1 from '../img/P1.png';
import P2 from '../img/P2.png';
import P3 from '../img/P3.png';
export default function Use() {
    return (
      <Card aria-roledescription="card avatar">
        <Card.Header fitted>
          <Flex column>
            <Text content="使用方法" weight="bold" />
          </Flex>
        </Card.Header>
        <Card.Body fitted>
          <Flex column gap="gap.small">
            <Header color="green">安卓使用方法</Header>
            <a target="_blank" href="https://rainballs-my.sharepoint.com/:u:/p/plxdhong/Edcli18LzeZPnzNDuHDFYY4BtygEXa1FYfDoSp3i7WJAkg?e=5eeTh6">点击下载AnXray</a>
            <Text>密码：welcometorainballs</Text>
            <Text>请在2021年11月9日之前下载</Text>
            <Text>安装AnXray，如下图进行配置</Text>
            <img src={A1} />
            <img src={A2} />
            <img src={A3} />
            <Header color="green">苹果使用方法</Header>
            <Text>安装Shadowrocket，如下图进行配置</Text>
            <img src={I1} />
            <img src={I2} />
            <Header color="blue">电脑使用方法</Header>
            <a target="_blank" href="https://dotnet.microsoft.com/download/dotnet/thank-you/runtime-desktop-5.0.11-windows-x64-installer">点击安装.net5环境</a>
            <a target="_blank" href="https://rainballs-my.sharepoint.com/:u:/p/plxdhong/EVTZ1vI0EhNEmJihsthL5rgBB-3UxArqPhYuCMuGO4MFaw?e=a6dv6i">点击安装Netch软件</a>
            <Text>密码：welcometorainballs</Text>
            <Text>请在2021年11月9日之前下载</Text>
            <Text>点击二维码下方的复制</Text>
            <Text>在Netch，如下图进行配置</Text>
            <img src={P1} />
            <img src={P2} />
            <img src={P3} />
          </Flex>
        </Card.Body>
      </Card>
    );
  }