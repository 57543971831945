import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from "oidc-react";
import { useRequest } from 'ahooks';
import { Button,Input,Flex,Header, Table,tableHeaderCellBehavior } from '@fluentui/react-northstar'

function ListUser() {
  const auth = useAuth();
  const { data, error, loading, run } = useRequest(() => ({
    url: `${process.env.REACT_APP_API}/api/user`,
    method: 'get',
    headers: {
      'Authorization': "Bearer "+auth.userData.access_token,
      'Content-Type': 'application/json'
    },
  }), {
    manual: true,
  });
 
  if (error) {
    return <div>failed to load</div>;
  }
  if (loading) {
    return <div>loading...</div>;
  }
  return (<Flex column>
    <Header content="User List"/>
    <Button onClick={()=>run()}>拉取</Button>
    <Table aria-label="table">
      <Table.Row header>
        <Table.Cell content="id" accessibility={tableHeaderCellBehavior} />
        <Table.Cell content="Name" accessibility={tableHeaderCellBehavior} />
        <Table.Cell content="uuid" accessibility={tableHeaderCellBehavior} />
        <Table.Cell content="Description" accessibility={tableHeaderCellBehavior} />
        <Table.Cell content="Email" accessibility={tableHeaderCellBehavior} />
        <Table.Cell content="电话" accessibility={tableHeaderCellBehavior} />
        <Table.Cell content="QQ" accessibility={tableHeaderCellBehavior} />
        <Table.Cell content="微信" accessibility={tableHeaderCellBehavior} />
        <Table.Cell content="创建日期" accessibility={tableHeaderCellBehavior} />
      </Table.Row>
      {data?(data.map((user,index)=>{
        return (
          <Table.Row>
            <Table.Cell>{user.id}</Table.Cell>
            <Table.Cell>{user.name}</Table.Cell>
            <Table.Cell>{user.uuid}</Table.Cell>
            <Table.Cell>{user.description}</Table.Cell>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{user.phone}</Table.Cell>
            <Table.Cell>{user.qq}</Table.Cell>
            <Table.Cell>{user.wechat}</Table.Cell>
            <Table.Cell>{user.created}</Table.Cell>
          </Table.Row>
        )
      })):"等待拉取数据"}
    </Table>
    </Flex>);

}

function AddUser() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const auth = useAuth();
  const [userData,setUserData]=useState({});
  const onSubmit = (data) => {
    setUserData(data);
    run();
  };
  const { error, loading, run } = useRequest(() => ({
    url: `${process.env.REACT_APP_API}/api/user`,
    method: 'post',
    headers: {
      'Authorization': "Bearer "+auth.userData.access_token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
  }), {
    manual: true,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="gap.small">
      <Header content="Add User"/>
      {loading?"Loading":"OK"}
      |Status {error?"Error":"OK"}
      |FORM {error?JSON.stringify(errors):"OK"}
      <Input type="text" placeholder="Id" {...register("Id", {required: true})} />
      <Input type="text" placeholder="Uuid" {...register("Uuid", {required: true})} />
      <Input type="text" placeholder="Name" {...register("Name", {required: true})} />
      <Input type="text" placeholder="Description" {...register("Description", {})} />
      <Input type="email" placeholder="Email" {...register("Email", {required: true})} />
      <Input type="tel" placeholder="Phone" {...register("Phone", {required: true})} />
      <Input type="text" placeholder="Wechat" {...register("Wechat", {required: true})} />
      <Input type="datetime" placeholder="Created 2019-07-26T00:00:00" {...register("Created", {required: true})} />

      <Button type="submit" >提交</Button>
      </Flex>
    </form>
  );
}

function UpdateUser() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const auth = useAuth();
  const [userData,setUserData]=useState({});
  const onSubmit = (data) => {
    setUserData(data);
    run();
  };
  const { error, loading, run } = useRequest(() => ({
    url: `${process.env.REACT_APP_API}/api/user/${userData.Id}`,
    method: 'put',
    headers: {
      'Authorization': "Bearer "+auth.userData.access_token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
  }), {
    manual: true,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="gap.small">
      <Header content="Update User"/>
      {loading?"Loading":"OK"}
      |Status {error?"Error":"OK"}
      |FORM {error?JSON.stringify(errors):"OK"}
      <Input type="text" placeholder="Id" {...register("Id", {required: true})} />
      <Input type="text" placeholder="Uuid" {...register("Uuid", {required: true})} />
      <Input type="text" placeholder="Name" {...register("Name", {required: true})} />
      <Input type="text" placeholder="Description" {...register("Description", {})} />
      <Input type="email" placeholder="Email" {...register("Email", {required: true})} />
      <Input type="tel" placeholder="Phone" {...register("Phone", {required: true})} />
      <Input type="text" placeholder="Wechat" {...register("Wechat", {required: true})} />
      <Input type="datetime" placeholder="Created 2019-07-26T00:00:00" {...register("Created", {required: true})} />

      <Button type="submit" >提交</Button>
      </Flex>
    </form>
  );
}

function DeleteUser() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const auth = useAuth();
  const [userData,setUserData]=useState({});
  const onSubmit = (data) => {
    setUserData(data);
    run();
  };
  const { error, loading, run } = useRequest(() => ({
    url: `${process.env.REACT_APP_API}/api/user/${userData.Id}`,
    method: 'delete',
    headers: {
      'Authorization': "Bearer "+auth.userData.access_token,
      'Content-Type': 'application/json'
    },
  }), {
    manual: true,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="gap.small">
      <Header content="Delete User"/>
      {loading?"Loading":"OK"}
      |Status {error?"Error":"OK"}
      |FORM {error?JSON.stringify(errors):"OK"}
      <Input type="text" placeholder="Id" {...register("Id", {required: true})} />
      <Button type="submit" >提交</Button>
      </Flex>
    </form>
  );
}

function PatchUser() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const auth = useAuth();
  const { error, loading, run } = useRequest(() => ({
    url: `${process.env.REACT_APP_API}/api/user`,
    method: 'patch',
    headers: {
      'Authorization': "Bearer "+auth.userData.access_token,
      'Content-Type': 'application/json'
    },
  }), {
    manual: true,
  });

  return (
    <Flex column gap="gap.small">
      <Header content="同步"/>
      <Button onClick={()=>run()}>同步</Button>
    </Flex>
  );
}

export default function User() {
  return (
    <Flex column>
      <ListUser/>
      <AddUser/>
      <UpdateUser/>
      <DeleteUser/>
      <PatchUser/>
    </Flex>
  )
}