import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { Card, Flex, Text,Loader,Status, Button,Divider,Input,Header,RadioGroup } from '@fluentui/react-northstar'
import { useAuth } from "oidc-react";
import { useRequest } from 'ahooks';
import { SyncIcon } from '@fluentui/react-icons-northstar'
import QRCode from 'qrcode.react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Use from "./Use";


function NewUser() {
  const auth = useAuth();
  const [userData,setUserData]=useState({});
  const [message, setMessage] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { error, loading, run } = useRequest(() => ({
    url: `${process.env.REACT_APP_API}/api/detail`,
    method: 'post',
    headers: {
      'Authorization': "Bearer "+auth.userData.access_token,
      'Content-Type': 'application/json'
    },
    body:JSON.stringify(userData)
  }), {
    manual: true,
    onSuccess: (result, params) => {
      if (result.success) {
        setMessage(true);
      }
    },
  });
  const onSubmit = (data) => {
    data.Created="2011-11-11"
    setUserData(data);
    run();
  };
 
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="gap.small">
      <Header content="请进行注册"/>
      <Text size="small" >请求状态{error?(<Status state="error" title="error" />):(<Status state="success" title="success" />)}</Text>
      <Text size="small" >表格状态{errors?(<Status state="error" title="error" />):(<Status state="success" title="success" />)}</Text>
      <Input disabled  type="text" placeholder="Id无需填写" {...register("Id", {})} />
      <Input disabled  type="text" placeholder="Uuid无需填写" {...register("Uuid", {})} />
      <Input  label="姓名" type="text" placeholder="Name" {...register("Name", {required: true})} />
      <Input  label="备注" type="text" placeholder="Description" {...register("Description", {})} />
      <Input  label="邮箱" type="email" placeholder="Email" {...register("Email", {required: true})} /> 
      <Input  label="接收验证码的手机"type="tel" placeholder="Phone" {...register("Phone", {required: true}) } />
      <Input  label="用于以后帮助自主服务的微信号" type="text" placeholder="Wechat" {...register("Wech at", {required: true})} />
      <Input disabled  type="datetime" placeholder="无需填写Created 2019-07-26T00:00:00" {...register("Created", {})} />
      {message?(<Text color="red">注册成功，请点击个人信息中的刷新按钮获取二维码</Text>):null}
      {loading?<Loader />:<Button type="submit" >提交</Button>}
      </Flex>
    </form>
  );
}



export default function Home() {
  const auth = useAuth();
  const { data, error, loading,run } = useRequest(() => ({
    url: `${process.env.REACT_APP_API}/api/detail`,
    method: 'get',
    headers: {
      'Authorization': "Bearer "+auth.userData.access_token,
      'Content-Type': 'application/json'
    },
  })
  );
  //true安卓 false苹果
  const [AoI,setAoI]=useState(true);
  const [copy,setCopy]=useState(false);
  if (error) return (
    <Flex column gap="gap.small" hAlign="center">
      <Card aria-roledescription="card avatar">
            <Card.Header fitted>
              <Flex column>
                <Text content="个人信息" weight="bold" />
                <Flex gap="gap.small"  vAlign="center">
                  <Text size="small" >{error?(<Status state="error" title="error" />):(<Status state="success" title="success" />)}</Text>
                  <Button icon={<SyncIcon />} iconOnly onClick={()=>{run();}}/>
                </Flex>
              </Flex>
            </Card.Header>
            <NewUser/>
      </Card>
    </Flex>
  );
    return (
      <Flex column gap="gap.small" hAlign="center">
        <Card aria-roledescription="card avatar">
            <Card.Header fitted>
              <Flex column>
                <Text content="个人信息" weight="bold" />
                <Flex gap="gap.small"  vAlign="center">
                  <Text size="small" >{error?(<Status state="error" title="error" />):(<Status state="success" title="success" />)}</Text>
                  <Button icon={<SyncIcon />} iconOnly onClick={()=>{run();}}/>
                </Flex>
              </Flex>
            </Card.Header>
            <Card.Body fitted>
            <Flex column hAlign="center" gap="gap.small">
            {loading?(<Loader />):(
              <Flex column hAlign="center" gap="gap.small">
                <RadioGroup
                items={[{name:'安卓',key:1,value:true,label:"安卓"},{name:'苹果',key:2,value:false,label:"苹果"}]} defaultCheckedValue={true} 
                onCheckedValueChange={(e,props)=>setAoI(props.value)}
              />
                {error?null:(
                  <QRCode
                  value={AoI?`vless://${data.uuid}@129.146.87.118:443/?type=tcp&encryption=none&security=xtls&sni=cw.dhsay.top&flow=xtls-rprx-direct#arm`:`vless://${window.btoa(`auto:${data.uuid}@129.146.87.118:443`)}?remarks=arm&obfs=none&tls=1&peer=cw.dhsay.top&xtls=1`}
                  size={128}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={false}
                  renderAs={"svg"}
                  imageSettings={{
                    src: AoI?"https://img.icons8.com/fluency/48/000000/android-os.png":"https://img.icons8.com/color/48/000000/ios-logo.png",
                    x: null,
                    y: null,
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}
              />
                )}
                {error?null:(
                  <CopyToClipboard text={`vless://${data.uuid}@129.146.87.118:443/?type=tcp&encryption=none&security=xtls&sni=cw.dhsay.top&flow=xtls-rprx-direct#arm`}
                  onCopy={() => setCopy(true)}>
                  <Button>点击复制(电脑使用)</Button>
                </CopyToClipboard>
                )}
                <CopyToClipboard text={`vless://${data.uuid}@129.146.87.118:443/?type=tcp&encryption=none&security=xtls&sni=cw.dhsay.top&flow=xtls-rprx-direct#arm`}
                  onCopy={() => setCopy(true)}>
                  <Button>点击复制(电脑使用)</Button>
                </CopyToClipboard>
                {copy ? <span style={{color: 'red'}}>成功复制.</span> : null}
              </Flex>
            )}
            </Flex>
            </Card.Body>
        </Card>
        <Divider content="我是分割线" />
        <Use></Use>
      </Flex>
    );
  }